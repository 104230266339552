import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet, View, Dimensions, NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { RootStackParamList } from '../App';
import * as AuthService from '../services/AuthService';
import { Button, Icon, Input, Link, Pressable, Stack } from 'native-base';
import { BeeHeader } from '../components/BeeHeader';
const MaterialIcons = React.lazy(() => import("@expo/vector-icons/build/MaterialIcons"));
//import MaterialIcons from '@expo/vector-icons/build/MaterialIcons';
import { isValidEmail } from '../services/ValidateHelper';
type Props = NativeStackScreenProps<RootStackParamList, 'Register'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  email: string;
  password: string;
  passwordConfirm: string;
  showPassword: boolean;
  dataValid: boolean;
}

export default class RegisterScreen extends React.Component<Props, State> {

  emailTimeout;

  constructor(props) {
    super(props);
    this.state = { email: "", password: "", passwordConfirm: "", showPassword: false, dataValid: false }
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onEmailChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ email: event.nativeEvent.text }, () => {
      if (this.emailTimeout) {
        clearTimeout(this.emailTimeout);
      }
      this.emailTimeout = setTimeout(() => { this.emailTimeout = null; this.checkDataValid() }, 500);
    }
    );
  }

  onPasswordChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ password: event.nativeEvent.text }, () => { this.checkDataValid() });
  }

  onPasswordConfirmChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ passwordConfirm: event.nativeEvent.text }, () => { this.checkDataValid() });
  }

  emailValid() {
    return isValidEmail(this.state.email);
  }

  passwordValid() {
    return this.state.password != "" && this.state.passwordConfirm != "" && this.state.password == this.state.passwordConfirm;
  }

  checkDataValid() {
    this.setState({ dataValid: (this.emailValid() && this.passwordValid()) });
  }

  onRegisterPressed() {
    //const flowId = this.props.route.params.flow;

    if (this.emailValid() && this.passwordValid()) {
      AuthService.registerUser({ email: this.state.email, password: this.state.password }).then((res) => {
        this.props.navigation.navigate('Verification', { fromRegistration: true});
      });
    }

  }

  render() {

    return (
      <View style={styles.container}>
        <BeeHeader title="Registration" navigation={this.props.navigation} />
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight }}>
          <View style={{ alignItems: 'center', marginTop: 32 }}>
            <Stack space={4} w={400} alignItems="center">
              <Input onChange={this.onEmailChanged.bind(this)} value={this.state.email} w={{
                base: "75%",
                lg: "100%"
              }} InputLeftElement={<Icon as={<MaterialIcons name="email" />} size={5} ml="2" color="muted.400" />} placeholder="Email" />
              <Input onChange={this.onPasswordChanged.bind(this)} value={this.state.password} w={{
                base: "75%",
                lg: "100%"
              }} type={this.state.showPassword ? "text" : "password"} InputRightElement={<Pressable onPress={() => this.toggleShowPassword()}>
                <Icon as={<MaterialIcons name={this.state.showPassword ? "visibility" : "visibility-off"} />} size={5} mr="2" color="muted.400" />
              </Pressable>} placeholder="Password" />
              <Input onChange={this.onPasswordConfirmChanged.bind(this)} value={this.state.passwordConfirm} w={{
                base: "75%",
                lg: "100%"
              }} type={this.state.showPassword ? "text" : "password"} InputRightElement={<Pressable onPress={() => this.toggleShowPassword()}>
                <Icon as={<MaterialIcons name={this.state.showPassword ? "visibility" : "visibility-off"} />} size={5} mr="2" color="muted.400" />
              </Pressable>} placeholder="Repeat Password" />

              <Button w={140} disabled={!this.state.dataValid} onPress={this.onRegisterPressed.bind(this)}>Register</Button>
            </Stack>
          </View>
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#FFF',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(255,255,255,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  }
});
