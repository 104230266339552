import { gql } from "@apollo/client";
import { apollo_client } from "../App";
import { Scale, ScaleData, ScaleDataDaily, ScaleDataEntry, ScalesDataDailyQueryResponse, ScalesQueryResponse } from "../types/types";

/*const scaleDataQuery = gql`
    query($scale_id: String!, $start: Date!, $end: Date!) {
        scaledata(scale_id: $scale_id, start: $start, end: $end) {
        weight
        beetemp
        pressure
        humidity
        bat0
        bat1
        timestamp
        }
    }
`
*/
const scaleDataQuery = gql`
    query($scale_id: String!, $start: Date!, $end: Date!) {
        
        scale(id: $scale_id)
        {
            id
            name
            owner_id
            scaledata_daily(start: $start, end: $end) {
                weight
                beetemp
                pressure
                humidity
                bat0
                bat1
                timestamp
            }
        }
    }
`

const ScalesQuery = gql`
    query {
        scales {
            id
            owner_id
            name
        }
    }
`

const ScalesQueryWithData = gql`
    query($start: Date!, $end: Date!) {
        scales {
            id
            owner_id
            name
            scaledata(start: $start, end: $end) {
                weight
                beetemp
                pressure
                humidity
                bat0
                bat1
                timestamp
            }
            scaledata_latest {
                weight
                beetemp
                pressure
                humidity
                bat0
                bat1
                timestamp
            }
        }
    }
`
export async function getScaleData(scaleId: string, start: Date, end: Date) : Promise<ScaleDataDaily>{
    //console.log(`getScaleData: ${scaleId} - ${start} - ${end}`);
    return new Promise((resolve, reject) => {
        apollo_client.query<ScalesDataDailyQueryResponse>({ query: scaleDataQuery, variables: { scale_id: scaleId, start: start, end: end}}).then((value) => {
            resolve(value.data.scale);
        },
        (err) => {
            reject(err);
        });
    })
    
}

export async function getMyScales() : Promise<Array<Scale>> {
    return new Promise((resolve, reject) => {
        apollo_client.query<ScalesQueryResponse>({ query: ScalesQuery}).then((value) => {
            resolve(value.data.scales);
        },
        (err) => {
            reject(err);
        });
    })
}

export async function getMyScalesWithData(start: Date, end: Date) : Promise<Array<Scale>> {
    return new Promise((resolve, reject) => {
        apollo_client.query<ScalesQueryResponse>({ query: ScalesQueryWithData, variables: { start, end }}).then((value) => {
            if(value?.data?.scales) {
                value.data.scales.sort((a,b) => {
                    if(!a.scaledata || a.scaledata.length == 0) {
                        return 1;
                    }

                    if(!b.scaledata || b.scaledata.length == 0) {
                        return -1;
                    }

                    return (b.scaledata[b.scaledata.length-1].timestamp - a.scaledata[a.scaledata.length-1].timestamp);
                })
            }
            resolve(value.data.scales);
        },
        (err) => {
            reject(err);
        });
    })
}

export function getFixedForValue(valueName: string) {
    if(valueName == "weight") {
       return 3;
    }
    else if(valueName == "pressure" || valueName == "beetemp") {
       return 1;
    }
    else if(valueName == 'bat0' || valueName == 'bat1') {
       return 2
    }
    return 0;
 }

 export function getUnitStringForValue(valueName: string) {
    if(valueName == "weight") {
        return "kg";
     }
     else if(valueName == "pressure") {
        
        return "hPa";
     }
     else if(valueName == "beetemp") {
        return "°C";
     }
     else if(valueName == 'bat0' || valueName == 'bat1') {
        return "V"
     }
     else if(valueName == "humidity") {
        return "%";
     }
     return "";
 }

 export function fillWithUndefinedDataToDate(scaleData: Array<ScaleDataEntry>, endDate: Date) {
    if(!scaleData || scaleData.length <= 0) {
        return;
    }
    const lastTime = new Date(scaleData[scaleData.length-1].timestamp);
    if(lastTime.getFullYear() != endDate.getFullYear() || lastTime.getMonth() != endDate.getMonth() || lastTime.getDate() != endDate.getDate()) {
        scaleData.push({ weight : undefined, bat0: undefined, bat1: undefined, beetemp: undefined, humidity: undefined, pressure: undefined, timestamp: endDate.getTime()});
      }
 }
/*
export async function convertScaleDataToDailyScaleData(data: Array<ScaleDataEntry>) {
    const newData: Array<ScaleDataEntry> = [];
      let curDate: Date = new Date(0);
      let curCount = 0;
      let curCumm: ScaleDataEntry;
      data.map((sdentry) => {
        const entryDate = new Date(sdentry.timestamp)
        if (curDate.getUTCDay() != entryDate.getUTCDay() || curDate.getUTCMonth() != entryDate.getUTCMonth() || curDate.getFullYear() != entryDate.getUTCFullYear()) {
          if (curCount > 0) {
            curCount++;
            newData.push({
              weight: 0.001 * curCumm.weight / curCount,
              beetemp: curCumm.beetemp / curCount,
              bat0: curCumm.bat0 / curCount,
              bat1: curCumm.bat1 / curCount,
              humidity: curCumm.humidity / curCount,
              pressure: curCumm.pressure / curCount,
              timestamp: curCumm.timestamp
            })
          }
          curDate = entryDate;
          curCount = 0;
          curCumm = { bat0: sdentry.bat0, bat1: sdentry.bat1, beetemp: sdentry.beetemp, humidity: sdentry.humidity, pressure: sdentry.pressure, timestamp: sdentry.timestamp, weight: sdentry.weight }; //JSON.parse(JSON.stringify(sdentry));
        }
        else {
          curCount++;
          curCumm.bat0 += sdentry.bat0;
          curCumm.bat1 += sdentry.bat1;
          curCumm.beetemp += sdentry.beetemp;
          curCumm.humidity += sdentry.humidity;
          curCumm.pressure += sdentry.pressure;
          curCumm.weight += sdentry.weight;
        }
      })

      return newData;
}
*/