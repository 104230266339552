import { bisectCenter, extent, map, min, range, sort } from "d3-array";
import { axisBottom, axisLeft } from "d3-axis";
import { scaleLinear, scaleUtc } from "d3-scale";
import { pointer, select } from "d3-selection";
import { area, curveLinear, curveStepBefore, Line, line } from "d3-shape";
import { transition } from "d3-transition";
import { zoom } from "d3-zoom";
import { Box, Center, Text } from "native-base";
import React, { Component } from "react";
import { View } from "react-native";
import { colors } from "../constants/colors";
import { GraphType, ScaleDataEntry } from "../types/types";

const bTransition = false;

type DataEntry = {
   timestamp: number;
   value: number;
}

type ScaleGraphType = {
   graphType: GraphType;
   valueName: string;
   graphColor: string;
}

const margin = { left: 44, right: 24, bottom: 24, top: 8 };
const legendHeight = 30;

type Props = {
   width: number;
   height: number;
   data: Array<ScaleDataEntry>;
   borderRadius?: number;
   showLegend?: boolean;
   dataStep: number;
   valueFixed: number;
   preventDefaultClick: boolean;
   scaleGraphType: Array<ScaleGraphType>;
   valueUnitString: String;
   //graphType: "line" | "bar" | "area";
}

type State = {
   graphWidth: number;
   graphHeight: number;
   scaleData?: Array<ScaleDataEntry>;
}

export class ScaleGraph extends Component<Props, State> {

   gRefPathArea;
   gRefXAxisArea;
   gRefYAxisArea;
   gRefLine;
   gRefBar;
   gRefLegend;
   svgRef;
   tooltipRef;

   pathDataElement;
   pathNoDataElement;
   lineElements = [];
   barElement;

   xData = [];
   yScales = {};
   xScale;
   yScale;
   D = [];
   Y = [];
   scaleData: Array<ScaleDataEntry>;

   constructor(props) {
      super(props);

      this.state = {
         graphWidth: this.props.width,
         graphHeight: this.calculateGraphHeight()
      }

      this.gRefXAxisArea = React.createRef();
      this.gRefYAxisArea = React.createRef();
      this.gRefPathArea = React.createRef();
      this.gRefLine = React.createRef();
      this.gRefBar = React.createRef();
      this.gRefLegend = React.createRef();
      this.svgRef = React.createRef();
      this.tooltipRef = React.createRef();
   }

   calculateGraphHeight() {
      let graphHeight = this.props.height;
      if (this.props.showLegend) {
         graphHeight -= legendHeight;
      }

      return graphHeight;
   }

   componentDidMount(): void {
      this.processData();
      this.updateGraph();
   }

   componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {

      if ((prevProps.width != this.props.width || prevProps.height != this.props.height) && prevProps.data == this.props.data) {
         this.setState({ graphWidth: this.props.width, graphHeight: this.calculateGraphHeight() }, () => {
            this.updateGraph();
         })
      }
      else if (prevProps.data != this.props.data) {
         console.log("Data changed");
         this.setState({ graphWidth: this.props.width, graphHeight: this.calculateGraphHeight() }, () => {
            this.processData();
            this.updateGraph();
         })
      }
      else if (prevProps.scaleGraphType != this.props.scaleGraphType) {
         this.updateGraph();
      }
   }

   zoomed() {

   }

   fillDataGaps(data: Array<ScaleDataEntry>, dataStep: number): Array<ScaleDataEntry> {
      const timeExtent = extent(data, (d) => d.timestamp);

      const days = Math.round((timeExtent[1] - timeExtent[0]) / dataStep);
      const newData: Array<ScaleDataEntry> = [];

      newData.length = days;

      const startMilli = timeExtent[0];

      for (let i = 0; i < data.length; ++i) {
         const dateIndex = Math.round((data[i].timestamp - startMilli) / dataStep);
         //newData[dateIndex] = { timestamp: data[i].timestamp, value: data[i][this.props.selectedKey] };
         newData[dateIndex] = data[i];
      }

      for (let i = 0; i < days; ++i) {
         if (!newData[i]) {
            newData[i] = { timestamp: startMilli + i * dataStep, bat0: undefined, bat1: undefined, beetemp: undefined, humidity: undefined, pressure: undefined, weight: undefined };
         }
      }

      return newData;
   }

   hasData() {
      if (!this.gRefXAxisArea.current || !this.gRefXAxisArea.current) {
         return true;
      }
      return this.scaleData && this.scaleData.length > 0;
   }

   processData() {
      if (!this.props.data?.length) {
         this.scaleData = [];
         return;
      }

      this.scaleData = this.fillDataGaps(this.props.data, this.props.dataStep);

      this.xData = sort(map(this.scaleData, (d) => d.timestamp));
      this.xScale = undefined;
      this.yScales = {};
   }

   pointermoved(event) {
      //console.log(event)
      const i = bisectCenter(this.xData, this.xScale.invert(pointer(event)[0]));
      const hasData = this.Y[i] != null;
      const xPos = hasData ? this.xScale(this.xData[i]) : event.layerX;
      const yPos = hasData ? this.yScale(this.Y[i]) + 10 : event.layerY;
      const xPosBox = xPos < 0.5*this.state.graphWidth ? 70 : -70;
      const yPosBox = event.layerY;
      const yValue = hasData ? `${this.Y[i].toFixed(this.props.valueFixed)} ${this.props.valueUnitString}` : "No data";
      const tooltip = select(this.tooltipRef.current);
      tooltip.style("display", null);
      tooltip.attr("transform", `translate(${xPos},${yPosBox})`);

      const svgNode = select(this.svgRef.current);

      tooltip.selectAll("circle")
         .data([,])
         .join("circle")
         .attr("cy", yPos - yPosBox)
         .attr("r", hasData ? 4 : 0)
         .attr("fill", "red");

      const line = tooltip.selectAll("line")
         .data([,])
         .join("line")
         .attr("y1", 0)
         .attr("y2", this.state.graphHeight - margin.bottom)
         .attr("stroke", "black");

      const path = tooltip.selectAll("path")
         .data([,])
         .join("path")
         .attr("fill", "white")
         .attr("stroke", "black");

      const text = tooltip.selectAll("text")
         .data([,])
         .join("text")
         .call(text => text
            .selectAll("tspan")
            .data([new Date(this.xData[i]).toISOString().slice(0, 10), yValue])
            .join("tspan")
            .attr("x", 0)
            .attr("y", (_, i) => `${i * 1.1}em`)
            .attr("font-weight", (_, i) => i ? null : "bold")
            .text(d => d));

      const { x, y, width: w, height: h } = (text.node() as SVGGraphicsElement).getBBox();
      //text.attr("transform", `translate(${(-w / 2)},${15 - y})`);
      //path.attr("d", `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
      path.attr("d", `M${-w / 2 - 10},5H${w / 2 + 10}v${h + 20}h-${w + 20}z`)
      .attr("fill", hasData ? 'white' : 'red');

      if (yPosBox > this.state.graphHeight * 0.5) {
         path.attr("transform", `rotate(180),translate(${-xPosBox}, 0)`);
         text.attr("transform", `translate(${(-w / 2) + xPosBox},${y - 25})`);
      }
      else {
         path.attr("transform", `rotate(0),translate(${xPosBox}, 0)`);
         text.attr("transform", `translate(${(-w / 2) + xPosBox},${15 - y})`);
      }
      svgNode.property("value", yValue).dispatch("input", { bubbles: true });

      // vertical line
      line.attr("transform", `translate(0,${-yPosBox})`)
   }

   pointerleft() {
      const tooltip = select(this.tooltipRef.current);
      tooltip.style("display", "none");
      const svgNode = select(this.svgRef.current);
      svgNode.node().value = null;
      svgNode.dispatch("input", { bubbles: true });
   }

   updateGraph(forceUpdate: boolean = false) {
      //console.log("updateGraph");
      if (!this.gRefXAxisArea || !this.gRefXAxisArea.current) {
         console.log("No refs");
         this.forceUpdate();
         setTimeout(() => this.updateGraph(true), 10);
         return;
      }
      const svgNode = select(this.svgRef.current);

      svgNode.on("pointerenter pointermove", this.pointermoved.bind(this))
         .on("pointerleave", this.pointerleft.bind(this));
         if(this.props.preventDefaultClick) {
            svgNode.on("touchstart", event => event.preventDefault());
         }

      if (!this.hasData()) {
         //console.log("No data");
         this.forceUpdate();
         return;
      }

      if (this.pathDataElement) {
         this.pathDataElement.remove();
         this.pathDataElement = null;
      }

      if (this.pathNoDataElement) {
         this.pathNoDataElement.remove();
         this.pathNoDataElement = null;
      }

      if (this.lineElements) {
         this.lineElements.map((lineElement) => {
            lineElement.remove();
         })

         this.lineElements = [];
      }
      if (this.barElement) {
         this.barElement.remove();
         this.barElement = null;
      }

      let linePath;

      /*const graphZoom = zoom()
         .scaleExtent([1, 64])
         .extent([[margin.left, 0], [this.props.width - margin.right, this.props.height - margin.bottom]])
         .translateExtent([[0, -Infinity], [this.props.width - margin.right, Infinity]])
         .on("zoom", this.zoomed);
*/

      for (let i = 0; i < this.props.scaleGraphType.length; ++i) {
         const valueName = this.props.scaleGraphType[i].valueName; //this.props.selectedKey;
         const graphType = this.props.scaleGraphType[i].graphType;
         const graphColor = this.props.scaleGraphType[i].graphColor;
         this.Y = map(this.scaleData, (d) => d[valueName]);

         this.xScale =
            scaleUtc().
               domain(extent(this.xData)).
               range([margin.left, this.props.width - margin.right]);

         this.yScale =
            scaleLinear().
               domain(extent(this.Y)).
               range([this.state.graphHeight - margin.bottom - margin.top, 0]);

         const defined = (d, i) => !isNaN(this.xData[i]) && !isNaN(this.Y[i]);
         this.D = map(this.scaleData, defined);



         if (graphType == 'area') {
            const I = range(this.xData.length);
            const minValue = this.yScale(min(this.scaleData, (d) => d[valueName]));
            let graphArea = area<number>()
               .defined(i => this.D[i])
               .curve(curveStepBefore)
               .x(i => this.xScale(this.scaleData[i].timestamp))
               .y0(minValue)
               .y1(i => this.yScale(this.scaleData[i][valueName]));

            if (this.gRefPathArea.current) {
               if (!this.pathNoDataElement) {
                  this.pathNoDataElement = select(this.gRefPathArea.current).append('path');
               }
               if (!this.pathDataElement) {
                  this.pathDataElement = select(this.gRefPathArea.current).append('path');
               }

               this.pathNoDataElement.attr('d', graphArea(I.filter((d, i) => this.D[i]))).attr('fill', colors.chartDataNoData);


               if (bTransition) {
                  this.pathNoDataElement.transition(transition().duration(1000));
                  this.pathDataElement.transition(transition().duration(1000));
               }
               this.pathDataElement.attr('d', graphArea(I)).attr('fill', graphColor);

            }

         }
         else if (graphType == 'line') {
            linePath = line<DataEntry>()
               .defined((d, i) => this.D[i])
               .x((d) => { return this.xScale(d.timestamp); })
               .y((d) => { return this.yScale(d[valueName]); })
               .curve(curveLinear);

            if (this.gRefLine.current) {
               //if (!this.lineElement) {
               const lineElement = select(this.gRefLine.current).append('path');
               //}
               lineElement
                  //this.lineElement.attr('id', 'line')
                  .datum(this.scaleData)
                  .attr('stroke', graphColor)
                  .attr('stroke-width', 2)
                  .attr('fill', 'none')
                  .attr('d', linePath);
               if (bTransition) {
                  lineElement.transition(transition().duration(1000));
               }
               this.lineElements.push(lineElement);
            }
         }
      }
      this.createXAxis(this.xScale);

      this.createYAxis(this.yScale);

      if (this.gRefLegend.current) {
         var legendNode = select(this.gRefLegend.current);

         if (legendNode) {
            legendNode.selectChildren().remove();
            this.props.scaleGraphType.map((g, i) => {
               legendNode.append("circle").attr("cx", 16 + i * 200).attr("cy", 18).attr("r", 8).style("fill", this.props.scaleGraphType[i].graphColor)
               legendNode.append("text").attr("x", 40 + i * 200).attr("y", 24).text(this.props.scaleGraphType[i].valueName).style("font-size", "20px").attr("alignment-baseline", "middle")
            })
         }
         else {
            console.log("No legend svg found");
         }

      }

      if(forceUpdate) {
         this.forceUpdate();
      }
      
      //this.setState({ xScale: xScale, xScaleAxis: xScale, yScale: yScale });
   }

   createXAxis(xScale) {
      let xAxis = (g, x) => g
         .call(axisBottom(x).ticks(this.props.width / 100).tickSizeOuter(0))

      if (this.gRefXAxisArea.current) {
         const tmpSel = select(this.gRefXAxisArea.current).
            //transition().
            call(xAxis, xScale).
            style("font", "16px roboto");

         if (bTransition) {
            tmpSel.transition();
         }
      }
   }

   createYAxis(yScale) {
      let yAxis = (g, x) => g.
         call(axisLeft(x).
            ticks(this.state.graphHeight / 50).
            tickSizeOuter(0).tickFormat((d) => {
               if(typeof d == 'number'){
                  const decimalPlaces = Math.max(0, 3 - Math.round(d).toString().length);
                  return d.toFixed(decimalPlaces).toString();
               }
               return d.toString();
            })
         );

      if (this.gRefYAxisArea.current) {
         const tmpSel = select(this.gRefYAxisArea.current).
            //transition().
            call(yAxis, yScale).
            style("font", "16px roboto");
         if (bTransition) {
            tmpSel.transition();
         }
         select(this.gRefYAxisArea.current).
            select('.ylineg').
            remove();

         select(this.gRefYAxisArea.current).append('g').attr('class', 'ylineg').selectAll("line.line").data(yScale.ticks(this.state.graphHeight / 50)).enter()
            .append("line")
            .attr("x1", 0)
            .attr("x2", this.props.width - margin.right - margin.left)
            .attr("y1", (d) => yScale(d))
            .attr("y2", (d) => yScale(d))
            .attr('stroke', colors.black)
            .attr('stroke-width', 0.2)
            .style("stroke-dasharray", ("3, 3"))
      }
   }

   render() {
      return (
         <Box w={this.props.width} h={this.props.height}>
            {!this.hasData() &&
               <Center borderWidth={2} h="100%" backgroundColor="#FFD">
                  <Text fontSize="2xl">No data!</Text>
               </Center>
            }
            {this.hasData() &&
               <>
                  <View style={{ borderTopLeftRadius: this.props.borderRadius, borderTopRightRadius: this.props.borderRadius, backgroundColor: colors.chartBackground, width: this.props.width, height: this.state.graphHeight }}>
                     <svg
                        width={this.props.width}
                        height={this.state.graphHeight}
                        ref={this.svgRef}
                     >
                        <g ref={this.gRefXAxisArea} transform={`translate(0,${this.state.graphHeight - margin.bottom})`}>
                        </g>

                        <g ref={this.gRefPathArea} transform={`translate(0, ${margin.top})`}>
                        </g>

                        <g ref={this.gRefLine} transform={`translate(0, ${margin.top})`}>
                        </g>

                        <g ref={this.gRefBar}>
                        </g>

                        <g ref={this.gRefYAxisArea} transform={`translate(${margin.left},${margin.top})`}>
                        </g>

                        <g ref={this.tooltipRef} pointerEvents="none">

                        </g>
                     </svg>
                  </View>
                  {this.props.showLegend &&
                     <View>
                        <svg ref={this.gRefLegend} height={legendHeight} width={this.state.graphWidth}>
                        </svg>
                     </View>
                  }
               </>
            }
         </Box>
      )
   }
}

/*
   {this.state.linePath && this.props.graphType == 'line' &&
                        <BeeLine data={this.state.new_data} lineGenerator={this.state.linePath(this.state.new_data)} />
                     }
*/
/*
["#66c2a5","#fc8d62","#8da0cb","#e78ac3","#a6d854","#ffd92f","#e5c494","#b3b3b3"]


{false && this.state.graphType != 'area' &&
                        <g ref={this.gRefXAxis}>
                            {this.state.xScale &&
                                <>


                                    <Axis orient='bottom' scale={this.state.xScaleAxis} ticks={10} transform="translate(0,500)" />

                                </>
                            }
                        </g>
                    }
*/
