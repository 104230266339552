import Constants from 'expo-constants';

//const localhost = Platform.OS === 'ios' ? 'localhost:8080' : '10.0.2.2:8080';

const ENV = {
  dev: {
    AuthBaseUrl: 'http://localhost:4433',
    ApiUrl: 'http://localhost:5001/graphql',
    LinkingUrl: 'http://localhost:19006'

  },
  staging: {
    AuthBaseUrl: 'https://beeauth.runi.de',
    ApiUrl: 'https://beeapi.runi.de/graphql',
    LinkingUrl: 'https://bee.runi.de'
  },
  prod: {
    AuthBaseUrl: 'https://beeauth.runi.de',
    ApiUrl: 'https://beeapi.runi.de/graphql',
    LinkingUrl: 'https://bee.runi.de'
  },
};

const getEnvVars = (env = Constants.manifest.releaseChannel) => {
  // What is __DEV__ ?
  // This variable is set to true when react-native is running in Dev mode.
  // __DEV__ is true when run locally, but false when published.
  if (__DEV__) {
    return ENV.dev;
  } else if (env === 'staging') {
    return ENV.staging;
  } else {
    return ENV.prod;
  }
};

export default getEnvVars;