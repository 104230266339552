import React, { Suspense } from 'react';
import { StyleSheet, View } from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import HomeScreen from './screens/HomeScreen';
import ScaleScreen from './screens/ScaleScreen';
import LoginScreen from './screens/LoginScreen';
import RecoverScreen from './screens/RecoverScreen';
import RegisterScreen from './screens/RegisterScreen';
import SettingsScreen from './screens/SettingsScreen';
import VerificationScreen from './screens/VerificationScreen';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { NativeBaseProvider, Text } from "native-base";
import getEnvVars from './environment';
import SimpleBackdrop from './components/Backdrop';

const LinearGradient = require('expo-linear-gradient').LinearGradient;

export type RootStackParamList = {
  Home: undefined;
  Register: { flow: string };
  Login: { flow: string };
  Scale: { id: string };
  Verification: { flow: string, fromRegistration?: boolean };
};

const Stack = createStackNavigator();

const config = {
  screens: {
    Home: 'home',
    Login: 'login',
    Register: 'register',
    Settings: 'settings',
    Scale: 'scale',
    Recover: 'recovery',
    Verification: 'verification'
  },
};

const linking = {
  prefixes: [getEnvVars().LinkingUrl],
  config,
};

const nb_config = {
  dependencies: {
    'linear-gradient': LinearGradient,
  },
};
 
export const apollo_client = new ApolloClient({
  uri: getEnvVars().ApiUrl,
  cache: new InMemoryCache(),
  credentials: 'include'
});


function App(props) {

  let cookiesAllowed = false;

  const cookieString = document.cookie
  .split("; ")
  .find((row) => row.startsWith("cookiepref="))
  ?.split("=")[1];

  //const output = document.getElementById("cookiepref");
  //console.log("Cookie: ", cookieString);

  if(cookieString) {
    const cookieData = JSON.parse(cookieString);

    if(cookieData && cookieData.allowTracking) {
      cookiesAllowed = true;  
    }
  }
  //const cookiePref = { allowTracking: true, allowEssential: true};
  //document.cookie = `cookiepref=${JSON.stringify(cookiePref)};max-age=31.536.000;path=/`
  
  return (
    <Suspense fallback={SimpleBackdrop()}>
    <View style={styles.containerMain}>
      <NavigationContainer linking={linking}>
        <NativeBaseProvider config={nb_config}>
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="Scale" component={ScaleScreen} />
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Recover" component={RecoverScreen} />
            <Stack.Screen name="Settings" component={SettingsScreen} />
            <Stack.Screen name="Register" component={RegisterScreen} />
            <Stack.Screen name="Verification" component={VerificationScreen} />
          </Stack.Navigator>
          { !cookiesAllowed && 
            <View style= {{ backgroundColor: '#FFF'}}>
              <Text alignSelf="center">Cookie consent</Text>
            </View>
          }
        </NativeBaseProvider>
      </NavigationContainer>
      
    </View>
    </Suspense>
  );
}


const styles = StyleSheet.create({
  containerMain: {
    flex:1,
    backgroundColor: '#000',
    overflow: 'hidden',
    padding: 1
  },
});

export default App;