import { Box, HStack, ScrollView, Stack, Button } from "native-base";
import React, { Component } from "react";
import { View, ViewProps, Text } from "react-native";
import { colors } from "../constants/colors";
import { getFixedForValue, getUnitStringForValue } from "../services/ScaleDataService";
import { GraphType, ScaleDataEntry } from "../types/types";
import { BeeButton } from "./BeeButton";
import { ScaleGraph } from "./ScaleGraph";



type Props = {
   width: number;
   height: number;
   data: Array<ScaleDataEntry>
} & ViewProps;

type State = {
   selectedKey: string;
   graphType: GraphType;
}

export class ScaleGraphContainer extends Component<Props, State> {

   constructor(props) {
      super(props);

      this.state = {
         graphType: "line",
         selectedKey: 'weight'
      }
   }

   setKey(newKey: string) {
      this.setState({ selectedKey: newKey });

      this.props.children
   }

   setGraphType(graphType: GraphType) {
      this.setState({ graphType: graphType });
   }

   render() {
      //console.log("PropsWidth: ", this.props.width)
      return (
         <Box w={this.props.width}>
            <Stack direction={["column", "column", "column", "column", "row"]} alignItems="center">
               <ScrollView horizontal w={this.props.width} showsHorizontalScrollIndicator={false}>
                  <Button.Group flex={1} colorScheme="blue" mx={{
                     base: "auto",
                     md: 0,
                  }} size="sm" isAttached>
                     <Button w={120} variant={this.state.selectedKey == 'weight' ? "solid" : 'subtle'} onPress={() => this.setKey('weight')} fontSize="md">Weight</Button>
                     <Button w={120} variant={this.state.selectedKey == 'beetemp' ? "solid" : 'subtle'} onPress={() => this.setKey('beetemp')} fontSize="md">Temperature</Button>
                     <Button w={120} variant={this.state.selectedKey == 'humidity' ? "solid" : 'subtle'} onPress={() => this.setKey('humidity')} fontSize="md">Humidity</Button>
                     <Button w={120} variant={this.state.selectedKey == 'pressure' ? "solid" : 'subtle'} onPress={() => this.setKey('pressure')} fontSize="md">Pressure</Button>
                     <Button w={120} variant={this.state.selectedKey == 'bat0' ? "solid" : 'subtle'} onPress={() => this.setKey('bat0')} fontSize="md">Battery 0</Button>
                     <Button w={120} variant={this.state.selectedKey == 'bat1' ? "solid" : 'subtle'} onPress={() => this.setKey('bat1')} fontSize="md">Battery 1</Button>
                  </Button.Group>
               </ScrollView>
               <Box>
               <Button.Group flex={1} justifyContent="flex-end" colorScheme="green" mx={{
                  base: "auto",
                  md: 0,
               }} size="sm" isAttached>
                  <Button w={120} variant={this.state.graphType == 'line' ? "solid" : 'subtle'} onPress={() => this.setGraphType("line")} fontSize="md">Line</Button>
                  <Button w={120} variant={this.state.graphType == 'area' ? "solid" : 'subtle'} onPress={() => this.setGraphType("area")} fontSize="md">Area</Button>
               </Button.Group>
               </Box>

            </Stack>
            <View style={{ marginVertical: 4 }}>
               <ScaleGraph 
                  dataStep={86400000} 
                  scaleGraphType={[{ graphType: this.state.graphType, valueName: this.state.selectedKey, graphColor: colors.chartDataPrimary }]} 
                  data={this.props.data} 
                  width={this.props.width} 
                  height={this.props.height - 100} 
                  valueFixed={getFixedForValue(this.state.selectedKey)}
                  valueUnitString={getUnitStringForValue(this.state.selectedKey)}
                  preventDefaultClick={true}
                  />

                  
            </View>
         </Box>
      )
   }
}
