import { rgb } from "d3-color";

export const colors = {
    primary: '#200258', //'#F99417',
    primary2: '#D67229',
    secondary: '#10A19D',
    lightGray: '#F5F5F5',
    gray: '#9E9E9E',
    darkGray: '#616161',
    white: '#FFFFFF',
    black: '#000000',
    chartDataPrimary: '#A53645', //#E3B778',
    chartDataNoData: '#F8F',
    chartBackground: '#F5F1E5',
    chartBackgroundNoData: '#FF9195',
    textPrimary: '#F5F5F5'
  };