import { StyleSheet } from 'react-native';
import { colors } from './colors';

export const styles = StyleSheet.create({
    //header: { height: 40, backgroundColor: Themes[this.props.themeName].HeaderBackgroundColor },
    headerText: { paddingHorizontal: 8, fontSize: 16, fontFamily: 'roboto' },
    tableText: { padding: 2, paddingHorizontal: 8, fontSize: 16, fontFamily: 'roboto_light', fontWeight: '100' },
    nameText: { padding: 2, paddingHorizontal: 8, fontSize: 18, fontFamily: 'roboto_bold' },
    text: { textAlign: 'center', fontWeight: '100', fontSize: 20 },
    dataWrapper: { marginTop: -1 },
    col: { height: 28, backgroundColor: '#F00', flexDirection: 'column'},
    btn: { padding: 0, margin: 0, marginLeft: 1, backgroundColor: '#c8e1ff' },
    btnText: { textAlign: 'center', fontSize: 16 },
    detailCell: { width: '100%', borderWidth: 1, alignItems: 'center'},
    container: {
      flex: 1,
      backgroundColor: '#F5FCFF'
    },
    header: {
      height: 50,
      backgroundColor: colors.primary,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold'
    },
    menuButton: {
      position: 'absolute',
      right: 10
    },
    menuButtonText: {
      fontSize: 20
    },
    
    
    scrollView: {
      
      width: '100%'
      //marginTop: 10,
      //height: '100%'
    },
    aboutUs: {
      margin: 20,
      alignItems: 'center'
    },
    heading: {
      fontSize: 20,
      fontWeight: 'bold'
    },
    description: {
      marginTop: 10,
      textAlign: 'center',
      fontSize: 16
    },
    contactUs: {
      margin: 20,
      alignItems: 'center'
    },
    form: {
      marginTop: 10,
      fontSize: 16
    },
    menuContainer: {
      position: 'absolute',
      right: 0,
      top: 50,
      backgroundColor: colors.secondary,
      padding: 2,
      borderWidth: 1,
      borderColor: '#ddd',
      zIndex: 1000
    },
    menu: {
      backgroundColor: colors.primary
    },
    menuHeader: {
      padding: 10
    },
    closeButton: {
      alignSelf: 'flex-end',
      marginTop: 10
    },
    closeButtonText: {
      fontSize: 20
    },
    menuBody: {
      padding: 10,
      zIndex: 1200
    },
    menuItem: {
      padding: 10
    },
    menuItemText: {
      fontSize: 16,
      color: colors.textPrimary
    },
    separator: {
      height: 1,
      backgroundColor: '#ddd',
      marginVertical: 10
    }
  });
  