import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import { RootStackParamList } from '../App';
import * as AuthService from '../services/AuthService';
type Props = NativeStackScreenProps<RootStackParamList, 'Login'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  password: string;
  showPassword: boolean;
}

export default class SettingsScreen extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state={ password: "", showPassword: false}
  }

  onChangePasswordPressed() {
    AuthService.changePassword(this.state.password).then(
      () => {
      }
    );   
  }

  render() {

    return (
      <View style={styles.container}>
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight}}>
            <View style={{ alignItems: 'center'}}>
              <Text>Set new password</Text>
              <View style={{ flexDirection: 'row' }}>
                <Text>Password:</Text>
                <TextInput secureTextEntry={!this.state.showPassword} onChangeText={(text) => this.setState({ password: text})} value={this.state.password} style={{ width: 400 }}/>
              </View>
              <TouchableOpacity onPress={this.onChangePasswordPressed.bind(this)}>
                <View style={{ width: 200, height: 24, backgroundColor: '#F00'}}>
                  <Text>Set Password</Text>
                </View>
              </TouchableOpacity>
            </View>  
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#FFF',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(255,255,255,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  }
});
