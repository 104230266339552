import { SettingMethod } from "./types/auth_types";

type AuthAssuranceLevel = "aal1" | "aal2";

type AuthAddress = {
   id: string;
   value: string;
   verified: boolean;
   via: string; // "email", ?
   status?: string; // "completed", ?
   verified_at?: Date;
   created_at: Date;
   updated_at: Date;
}

type AuthenticationMethod = {
   method: SettingMethod;
   aal: AuthAssuranceLevel;
   completed_at: Date
}

type AuthDevice = {
   id: string;
   ip_address: string;
   user_agent: string;
   location: string;
}

type AuthIdentity = {
   id: string;
   schema_id: string;
   schema_url: string;
   state: string; // "active", ?
   state_changed_at: Date;
   traits: {
      name: {};
      email: string;
   };
   verifiable_addresses: Array<AuthAddress>;
   recovery_addresses: Array<AuthAddress>;
   metadata_public: {};
   created_at: Date;
   updated_at: Date;
}

export type SessionData = {
   id: string;
   active: boolean;
   expires_at: Date;
   authenticated_at: Date;
   authenticator_assurance_level: AuthAssuranceLevel;
   authentication_methods: Array<AuthenticationMethod>;
   issued_at: Date;
   identity: AuthIdentity;


   devices: Array<AuthDevice>;
}

type Globals = {
   sessionData: SessionData | null;
}

export const globals: Globals = {
   sessionData: null
}