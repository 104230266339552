import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { } from 'react';
import { View, Dimensions, ScrollView, ScaledSize, EmitterSubscription } from 'react-native';
import { RootStackParamList } from '../App';
import { BeeHeader } from '../components/BeeHeader';
import { styles } from '../constants/styles';
import * as ScaleDataService from '../services/ScaleDataService';

import { ScaleData } from '../types/types';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ScaleGraphContainer } from '../components/ScaleGraphContainer';
import { Box, Button, Center, HStack, Skeleton, Spinner, Stack, Text } from 'native-base';

type Props = NativeStackScreenProps<RootStackParamList, 'Scale'>;

type State = {
  hasScaleData: boolean;
  scaleData: ScaleData;
  startDate: Date;
  endDate: Date;
  error?: string;
  windowDims: ScaledSize;
}

export default class ScaleScreen extends React.Component<Props, State> {

  private focusListener;
  private dimsSubcription: EmitterSubscription;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.renderLoading = this.renderLoading.bind(this);
  }

  getInitialState() {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    return {
      hasScaleData: false,
      scaleData: null,
      endDate: new Date(),
      startDate: startDate,
      error: undefined,
      windowDims: Dimensions.get('window')
    };
  }

  componentDidMount(): void {
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));
    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", () => {
      this.onFocus();
    });

    this.focusListener = navigation.addListener("blur", () => {
      this.setState(this.getInitialState());
    });

    this.onFocus();
  }

  onDimsChanged({ window, screen }) {
    //console.log("dims changed: ", window);
    this.setState({ windowDims: window }, () => {
      this.forceUpdate();
    });
  }

  onFocus() {
    this.setState(this.getInitialState(), () => {
      this.getScaleData();
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.startDate != this.state.startDate || prevState.endDate != this.state.endDate) {
      if (this.state.startDate >= this.state.endDate) {
        this.setState({ startDate: prevState.startDate, endDate: prevState.endDate });
      }
      else {
        this.getScaleData();
      }
    }
  }

  componentWillUnmount(): void {

    if (this.focusListener) {
      this.focusListener();
      this.focusListener = null;
    }

    this.dimsSubcription?.remove();
  }

  getScaleData() {
    if (!this.props.route?.params?.id) {
      this.setState({ error: 'No scale specified' });
      return;
    }
    const scaleId = this.props.route.params.id;

    //var d3 = new Date();
    //d3.setMonth(d3.getMonth() - 5);
    ScaleDataService.getScaleData(scaleId, this.state.startDate, this.state.endDate).then((scaleData) => {

      const scaleDataDaily = scaleData.scaledata_daily.slice();
      ScaleDataService.fillWithUndefinedDataToDate(scaleDataDaily, this.state.endDate);

      const newData: ScaleData = { name: scaleData.name, owner_id: scaleData.owner_id, scaledata: scaleDataDaily };
      /*let curDate: Date = new Date(0);
      let curCount = 0;
      let curCumm: ScaleDataEntry;
      scaleData.scaledata_daily.map((sdentry) => {
        const entryDate = new Date(sdentry.timestamp)
        if (curDate.getUTCDay() != entryDate.getUTCDay() || curDate.getUTCMonth() != entryDate.getUTCMonth() || curDate.getFullYear() != entryDate.getUTCFullYear()) {
          if (curCount > 0) {
            curCount++;
            newData.scaledata.push({
              weight: 0.001 * curCumm.weight / curCount,
              beetemp: curCumm.beetemp / curCount,
              bat0: curCumm.bat0 / curCount,
              bat1: curCumm.bat1 / curCount,
              humidity: curCumm.humidity / curCount,
              pressure: curCumm.pressure / curCount,
              timestamp: curCumm.timestamp
            })
          }
          curDate = entryDate;
          curCount = 0;
          curCumm = { bat0: sdentry.bat0, bat1: sdentry.bat1, beetemp: sdentry.beetemp, humidity: sdentry.humidity, pressure: sdentry.pressure, timestamp: sdentry.timestamp, weight: sdentry.weight }; //JSON.parse(JSON.stringify(sdentry));
        }
        else {
          curCount++;
          curCumm.bat0 += sdentry.bat0;
          curCumm.bat1 += sdentry.bat1;
          curCumm.beetemp += sdentry.beetemp;
          curCumm.humidity += sdentry.humidity;
          curCumm.pressure += sdentry.pressure;
          curCumm.weight += sdentry.weight;
        }*/
      //})
      //this.scaleData = makeGraph(newData);
      this.setState({ hasScaleData: true, scaleData: newData });
      //console.log("ConsoleData: ", this.scaleData);
      //})
    },
      (err) => {
        this.setState({ error: err });
      });

  }



  onRecoverPressed() {
    this.props.navigation.navigate('Recover');
  }

  renderLoading() {
    const graphWidth = this.state.windowDims.width * 0.8;
    const height = 600 / graphWidth > 1 ? graphWidth : 600;
    return (
      <>

        <Center>
          <Box width={graphWidth} height={height + 40} >

            <Skeleton rounded="full" alignSelf="center" mt={4} w="30%" h={8} />
            <HStack my={2} space={2} alignSelf="center" h={8} w="20%">
              <Skeleton flex={1} rounded="full" h={8} />
              <Skeleton flex={1} rounded="full" h={8} />
            </HStack>
            <Skeleton h={8} my={1} />
            <Skeleton flex={1} />
          </Box>
        </Center>
      </>
    )
  }

  onStartMonthBack() {
    let newDate = new Date(this.state.startDate);
    newDate.setMonth(newDate.getMonth() - 1);
    this.setState({ startDate: newDate });
  }

  onStartMonthForward() {
    let newDate = new Date(this.state.startDate);
    newDate.setMonth(newDate.getMonth() + 1);

    if (newDate.getTime() < this.state.endDate.getTime()) {
      this.setState({ startDate: newDate });
    }
  }

  onEndMonthBack() {
    let newDate = new Date(this.state.endDate);
    newDate.setMonth(newDate.getMonth() - 1);
    if (newDate.getTime() > this.state.startDate.getTime()) {
      this.setState({ endDate: newDate });
    }

  }

  onEndMonthForward() {
    let newDate = new Date(this.state.endDate);
    newDate.setMonth(newDate.getMonth() + 1);
    const nowDate = new Date();
    if (newDate.getTime() < nowDate.getTime()) {
      this.setState({ endDate: newDate });
    }
    else {
      this.setState({ endDate: nowDate });
    }
  }

  render() {

    const graphWidth = this.state.windowDims.width * 0.8;

    //console.log("GraphWidth: ", graphWidth);
    const height = 600 / graphWidth > 1 ? graphWidth : 600;

    return (
      <View style={[styles.container, { height: this.state.windowDims.height, width: '100%' }]}>
        <BeeHeader title="Scale Details" navigation={this.props.navigation} />
        <View style={styles.container} >
          <ScrollView style={[styles.scrollView, { backgroundColor: '#FFF' }]}>
            {this.state.error &&
              <>
                <View>
                  <Text>Error: {this.state.error.toString()}</Text>
                </View>
              </>
              ||
              <>
                {this.state.hasScaleData &&
                  <View style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Text style={{ alignSelf: 'center', fontSize: 25 }}>{this.state.scaleData.name}</Text>
                    <Stack mb={2} space={[0,0,2]} zIndex={1500} alignSelf='center' direction={["column", "column", "row"]}>
                      <HStack space={1}>
                        <Button h={6} onPress={this.onStartMonthBack.bind(this)}>&lt;</Button>

                        <Box>
                          <DatePicker selected={this.state.startDate} onChange={(date) => this.setState({ startDate: date })} />
                        </Box>
                        <Button h={6} onPress={this.onStartMonthForward.bind(this)}>&gt;</Button>
                      </HStack>
                      <Text alignSelf="center" h={[4, 4, 6]}> - </Text>
                      <HStack space={1}>
                        <Button h={6} onPress={this.onEndMonthBack.bind(this)}>&lt;</Button>

                        <Box>

                          <DatePicker selected={this.state.endDate} onChange={(date) => this.setState({ endDate: date })} />

                        </Box>

                        <Button h={6} onPress={this.onEndMonthForward.bind(this)}>&gt;</Button>
                      </HStack>
                    </Stack>
                    <View style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                      {this.state.hasScaleData &&
                        <ScaleGraphContainer width={graphWidth} height={height} data={this.state.scaleData.scaledata} />
                      }
                    </View>
                  </View>
                }
                {!this.state.hasScaleData &&
                  <this.renderLoading />

                }
              </>
            }
          </ScrollView>
        </View>
      </View>
    );
  }
}


