const MaterialIcons = React.lazy(() => import("@expo/vector-icons/build/MaterialIcons"));//import MaterialIcons from '@expo/vector-icons/build/MaterialIcons';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Button, Icon, Input, Pressable, Stack, Text } from 'native-base';
import React from 'react';
import { StyleSheet, View, Dimensions, NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { RootStackParamList } from '../App';
import { BeeHeader } from '../components/BeeHeader';
import * as AuthService from '../services/AuthService';
import { isValidEmail } from '../services/ValidateHelper';
type Props = NativeStackScreenProps<RootStackParamList, 'Login'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  email: string;
  password: string;
  showPassword: boolean;
  dataValid: boolean;
}

export default class LoginScreen extends React.Component<Props, State> {

  emailTimeout;

  constructor(props) {
    super(props);
    this.state = { email: "", password: "", showPassword: false, dataValid: false }
  }

  onLoginPressed() {
    AuthService.loginUser({ email: this.state.email, password: this.state.password }).then(
      () => {
        this.props.navigation.replace('Home');
      }
    );
  }

  onRecoverPressed() {
    this.props.navigation.navigate("Recover");
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onEmailChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ email: event.nativeEvent.text }, () => {
      if (this.emailTimeout) {
        clearTimeout(this.emailTimeout);
      }
      this.emailTimeout = setTimeout(() => { this.emailTimeout = null; this.checkDataValid() }, 500);
    }
    );
  }

  onPasswordChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ password: event.nativeEvent.text });
    this.checkDataValid();
  }

  emailValid() {
    return isValidEmail(this.state.email);
  }

  passwordValid() {
    return this.state.password != "";
  }

  checkDataValid() {
    this.setState({ dataValid: (this.emailValid() && this.passwordValid()) });
  }

  sudmitEditingPassword() {
    if(this.emailValid && this.passwordValid()) {
      this.onLoginPressed();
    }
  }

  render() {

    return (
      <View style={styles.container}>
        <BeeHeader title="Login" navigation={this.props.navigation} />
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight }}>
          <View style={{ alignItems: 'center', marginTop: 32 }}>
            <Stack w={400} alignItems="center">
              <Input mt={2} onChange={this.onEmailChanged.bind(this)} value={this.state.email} w={{
                base: "75%",
                lg: "100%"
              }} InputLeftElement={<Icon as={<MaterialIcons name="email" />} size={5} ml="2" color="muted.400" />} placeholder="Email" />
              <Input mt={4} onChange={this.onPasswordChanged.bind(this)} onSubmitEditing={this.sudmitEditingPassword.bind(this)} value={this.state.password} w={{
                base: "75%",
                lg: "100%"
              }} type={this.state.showPassword ? "text" : "password"} InputRightElement={<Pressable onPress={() => this.toggleShowPassword()}>
                <Icon as={<MaterialIcons name={this.state.showPassword ? "visibility" : "visibility-off"} />} size={5} mr="2" color="muted.400" />
              </Pressable>} placeholder="Password" />
              <Pressable w="100%">
                <Text fontSize="md" fontWeight={500} color="indigo.500" onPress={this.onRecoverPressed.bind(this)} alignSelf="flex-end">
                  Forgot Password?
                </Text>
              </Pressable>
              <Button mt={2} w={140} disabled={!this.state.dataValid} onPress={this.onLoginPressed.bind(this)}>Login</Button>
            </Stack>
          </View>
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#FFF'
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(255,255,255,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  }
});
