import getEnvVars from "../environment";
import { globals } from "../global";
import * as Types from "../types/auth_types";

const authListeners = [];

export function addAuthListener(callbackFunc) {
  if (authListeners.indexOf(callbackFunc != -1)) {
    authListeners.push(callbackFunc);
  }
}

export function removeAuthListener(callbackFunc) {
  const idx = authListeners.indexOf(callbackFunc);

  if (idx != -1) {
    authListeners.splice(idx, 1);
  }
}

function notifyAuthListeners() {
  //console.log("Notify auth listeners");
  authListeners.map((callbackFunc) => {
    callbackFunc();
  })
}

export function getLoginState(): Types.AuthState {
  if (!globals.sessionData?.identity?.traits?.email) {
    return 'not_logged_in';
  }

  if (!globals.sessionData.active) {
    return 'inactive';
  }

  /*if(globals.sessionData.expires_at.getUTCMilliseconds() < Date.now()) {
    return 'expired';
  }*/

  if (globals.sessionData.identity.verifiable_addresses?.length > 0) {
    for (let i = 0; i < globals.sessionData.identity.verifiable_addresses.length; ++i) {
      if (globals.sessionData.identity.verifiable_addresses[i].verified) {
        return 'verified';
      }
    }
  }

  return 'unverified';
}

export async function whoami(): Promise<Types.WhoAmIResponse> {
  return new Promise((resolve, reject) => {
    fetch(`${getEnvVars().AuthBaseUrl}/sessions/whoami`, { credentials: 'include' }).then((res) => {
      res.json().then((sessionData) => {
        if (sessionData && !sessionData.error) {
          globals.sessionData = sessionData;
          notifyAuthListeners();
          //console.log(sessionData);
          resolve(sessionData);
        }
        else {
          //console.log(globals.sessionData);
          if (globals.sessionData) {
            globals.sessionData = null;
            //console.log("Notify1");
            notifyAuthListeners();
          }
          reject("not logged in");
        }
      },
        (err) => {
          //console.log(globals.sessionData)
          if (globals.sessionData) {
            globals.sessionData = null;
            //console.log("Notify2");
            notifyAuthListeners();
          }
          reject(err);
        })
    })
  })
}

export function getFlowID(flowType: Types.FlowType): Promise<string> {
  return new Promise((resolve, reject) => {
    fetch(`${getEnvVars().AuthBaseUrl}/self-service/${flowType}/browser`, {
      credentials: 'include', headers: {
        'Accept': 'application/json'
      }
    }).then((data) => {
      if (data) {
        data.json().then((bodyjson) => {
          resolve(bodyjson.id);
        })
      }
      else {
        reject("No data");
      }
    }).catch(err => {
      reject(err);
    })
  })
}

export async function registerUser(registrationData: Types.AuthRegistrationData) {
  const body: Types.RegistrationFlowBody = {
    method: "password",
    password: registrationData.password,
    "traits.email": registrationData.email
  }

  return createFlow<Types.RegistrationResponse, Types.RegistrationFlowBody>('registration', body, true);
}

export function getCSRFTokenFromFlowJson(flowsData: Types.FlowsData): string | null {

  if (flowsData?.ui?.nodes) {
    for (var i = 0; i < flowsData.ui.nodes.length; ++i) {
      const nodeData = flowsData.ui.nodes[i];

      if (nodeData.attributes?.name == 'csrf_token') {
        return nodeData.attributes.value;
      }
    }
  }

  return null;
}

export async function recoverUser(email: string): Promise<Types.RecoverUserResponse> {
  const body: Types.RecoveryFlowBody = {
    method: "code",
    email: email
  }

  return createFlow<Types.RecoverUserResponse, Types.RecoveryFlowBody>('recovery', body, true);
}

export async function loginUser(loginData: Types.AuthLoginData) {
  const body: Types.LoginFlowBody = {
    method: "password",
    password: loginData.password,
    identifier: loginData.email
  }

  return createFlow<Types.LoginResponse, Types.LoginFlowBody>('login', body, true);
}

export async function changePassword(password: string) {
  const body: Types.SettingsFlowBody = {
    method: "password",
    password: password
  }

  return createFlow<Types.SettingsResponse, Types.SettingsFlowBody>('settings', body, true);
}

export async function postRecoveryData2(csrfToken: string, flowId: string, code: string) {

  return postFlowData<Types.RecoveryResponse, Types.RecoveryCompleteBody>('recovery', flowId, { csrf_token: csrfToken, method: 'code', code: code });
}

export async function postVerificationData(flowId: string, code: string) {

  return postFlowData<Types.VerificationResponse, Types.VerificationCompleteBody>('verification', flowId, { method: 'code', code: code });
}

export async function getVerificationCode(flowId) : Promise<Types.FlowsData>{
  return new Promise((resolve, reject) => {
    return getFlows('verification', flowId, true).then((res_json) => {
      res_json.json().then((veriFlowData: Types.FlowsData) => {
        resolve(veriFlowData);
      },
      (err) => {
        reject(err);
      }
      );
    })
  })
}

export async function logout(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    fetch(`${getEnvVars().AuthBaseUrl}/self-service/logout/browser`, { credentials: 'include' }).then((data) => {
      //console.log(data);
      if (data) {
        data.json().then((jsondata) => {
          //resolve(jsondata.logout_url);
          fetch(jsondata.logout_url, { credentials: 'include', headers: { 'Accept': 'application/json' } }).then(() => {
            whoami().then(() => {
              resolve();
            },
              (err) => {
                resolve();
              })
          })
        },
          (err) => {
            reject();
          })
      }
      else {
        console.error("No data");
        reject();
      }
    })
  })
}

function getFlows(flowType: Types.FlowType, flowId: string, credentials: boolean) {
  let fetchOptions: RequestInit = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
  if (credentials) {
    fetchOptions.credentials = 'include';
  }

  return fetch(`${getEnvVars().AuthBaseUrl}/self-service/${flowType}/flows?id=${flowId}`, fetchOptions);
}




export async function createFlow<ResponseType extends Types.FlowResponse, BodyType extends Types.FlowBody>(flowType: Types.FlowType, body: BodyType, credentials: boolean): Promise<ResponseType> {
  return new Promise<ResponseType>((resolve, reject) => {
    getFlowID(flowType).then((flowId) => {

      let fetchOptions : RequestInit = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
      if(credentials) {
        fetchOptions.credentials = 'include';
      }
      fetch(`${getEnvVars().AuthBaseUrl}/self-service/${flowType}/flows?id=${flowId}`,fetchOptions).then((flowData) => {
        //flowData.json().then((jsonObject) => {

      //getFlows(flowType, flowId, credentials).then((flowData) => {
        //console.log("FlowData: ", JSON.stringify(flowData));
        flowData.json().then((jsonObject: Types.FlowsData) => {
          //console.log("FlowJson: ", JSON.stringify(jsonObject));

          const csrfToken = getCSRFTokenFromFlowJson(jsonObject);

          if (csrfToken) {
            body.csrf_token = csrfToken;
            postFlowData<ResponseType, BodyType>(flowType, flowId, body).then((result) => {
              result.csrfToken = csrfToken;
              result.flowId = flowId;
              resolve(result);
            },
              (err) => {
                reject(err);
              });
          }
          else {
            reject("CSRF Token not found");
            return;
          }
        })
      })
    })
  })
}

export async function postFlowData<ResponseType extends Types.FlowResponse, BodyType extends Types.FlowBody>(flowType: Types.FlowType, flowId: string, body: BodyType) {

  return new Promise<ResponseType>((resolve, reject) => {
    fetch(`${getEnvVars().AuthBaseUrl}/self-service/${flowType}?flow=${flowId}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then(
        (data) => {
          data.json().then((resval) => {
            //console.log(resval);
            resolve(resval);
          },
            (err) => {
              reject(err);
            })
        }
      )
  })
}
