import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { } from 'react';
import { View, Dimensions, TouchableOpacity, ScaledSize, EmitterSubscription } from 'react-native';
//import { ScrollView } from 'react-native-gesture-handler';
import { RootStackParamList } from '../App';
import { BeeHeader } from '../components/BeeHeader';
import * as AuthService from '../services/AuthService';
import { styles } from '../constants/styles';
import * as ScaleDataService from '../services/ScaleDataService';

import { Scale } from '../types/types';
//import Animated, { useAnimatedProps, useSharedValue } from 'react-native-reanimated';
import { ScaleGraph } from '../components/ScaleGraph';

import "react-datepicker/dist/react-datepicker.css";
import { Box, FlatList, VStack, Text, Flex, Divider, Center, Stack, Link } from 'native-base';
import { colors } from '../constants/colors';
import { getColorFromGradientByPercent } from '../services/ColorHelper';
import { globals } from '../global';

type Props = NativeStackScreenProps<RootStackParamList, 'Home'>;


type State = {
  useremail: string;
  hasScaleData: boolean;
  //scalesData: Array<Scale>;
  valueName: string;
  windowDims: ScaledSize;
  scaleGraphHeight: number;
  scaleGraphWidth: number;
}

export default class HomeScreen extends React.Component<Props, State> {

  scalesData= [];
  private focusListener;
  //private scaleData?: Array<ScaleDataEntry>;
  private dimsSubcription: EmitterSubscription;

  constructor(props) {
    super(props);

    this.state = {
      useremail: null,
      hasScaleData: false,
      //scalesData: [],
      valueName: 'weight',
      windowDims: Dimensions.get('window'),
      scaleGraphWidth: 600,
      scaleGraphHeight: 300
    };


  }

  onDimsChanged({ window, screen }) {
    //console.log("dims changed: ", window);
    this.setState({ windowDims: Dimensions.get('window') }, () => {
      this.forceUpdate();
    });
  }

  componentDidMount(): void {
    this.dimsSubcription = Dimensions.addEventListener("change", this.onDimsChanged.bind(this));
    const { navigation } = this.props;
    this.focusListener = navigation.addListener("focus", this.onFocus.bind(this));

    this.onFocus();
  }

  componentWillUnmount(): void {
    if (this.focusListener) {
      this.focusListener();
      this.focusListener = null;
    }

    this.dimsSubcription?.remove();
  }

  onFocus() {
    this.getUserData();
  }

  getUserData() {

    if(globals?.sessionData?.identity.traits.email)
    {
      this.setState({ useremail: globals.sessionData.identity.traits.email });
      this.getUserScales();
    }
    else {
      AuthService.whoami().then((sessionData) => {
        if (sessionData?.identity?.traits) {
          this.setState({ useremail: sessionData.identity.traits.email });
          this.getUserScales();
        }
        else {
          this.scalesData = null;
          this.setState({ useremail: null, hasScaleData: false });
        }
      },
        (err) => {
          this.scalesData = null;
          this.setState({ useremail: null, hasScaleData: false });
        })
      
    }
    
      
  }

  getUserScales() {

    const endDaysAgo = 0;
    const endDate = new Date(Date.now() - endDaysAgo * 24 * 60 * 60 * 1000);
    endDate.setUTCHours(23, 59, 59, 0);
    const startDate = new Date(Date.now() - (endDaysAgo + 7) * 24 * 60 * 60 * 1000);
    startDate.setUTCHours(0, 0, 0, 0);

    ScaleDataService.getMyScalesWithData(startDate, endDate).then((scales) => {
      this.scalesData = scales;
      this.setState({ hasScaleData: true });
    })
  }

  isLoggedIn() {
    return this.state.useremail != null;
  }

  onScaleClicked(index: number) {
    this.props.navigation.navigate("Scale", { id: this.scalesData[index].id });
  }

  renderScaleGraph(info) {
    
    const scaleValueName = "weight"; //this.state.valueNames[index]; //"weight";
    let scaleData = info.item.scaledata; // this.scalesData[index].scaledata;

    const nowDate = new Date();

    ScaleDataService.fillWithUndefinedDataToDate(scaleData, nowDate);

    return (
      <TouchableOpacity onPress={() => this.onScaleClicked(info.index)}>
        <Box flex={1} >
          {scaleData.length > 0 &&
            <ScaleGraph
              dataStep={900000}
              //borderRadius={16}
              showLegend={true}
              scaleGraphType={[{ graphType: "area", valueName: scaleValueName, graphColor: colors.chartDataPrimary }]}
              width={this.state.scaleGraphWidth}
              height={this.state.scaleGraphHeight}
              data={scaleData} 
              valueFixed={ScaleDataService.getFixedForValue(scaleValueName)}
              valueUnitString={ScaleDataService.getUnitStringForValue(scaleValueName)}
              preventDefaultClick={false}
              />
            ||
            <Center h={this.state.scaleGraphHeight} borderRadius={16} borderWidth={3} bgColor={colors.chartBackgroundNoData} w={this.state.scaleGraphWidth}>
              <Text fontSize="xl" bold>
                No recent data!
              </Text>
            </Center>
          }
        </Box>

      </TouchableOpacity>
    )
  }

  renderScaleData(index) {
    if (!this.scalesData[index].scaledata_latest) {

      // get latest entry

      return (
        <VStack borderLeftWidth={[0, 0, 0, 1]} pb={1} width={220} flex={1} justifyContent="flex-end" alignItems="flex-end">
          <Center bg={colors.chartBackgroundNoData} w={210} borderRadius={16} borderWidth={3} h={this.state.scaleGraphHeight}>
            <Text fontSize="xl" _dark={{
              color: "warmGray.50"
            }} color="coolGray.800" bold>
              No data!
            </Text>
          </Center>
        </VStack>)
    }
    const scaleData = this.scalesData[index].scaledata_latest;
    const full = 26;
    const empty = 3.5;
    const emptyVolt = 5.5;
    const fullVolt = 7.5;
    const batVoltage = (scaleData.bat0 + scaleData.bat1) / 2;
    const batPercentage = Math.min(1, Math.max(0, (batVoltage - emptyVolt) / (fullVolt - emptyVolt)));
    const batPercentValue = empty + Math.max(0.5, (full - empty) * batPercentage);
    const batFillColor = getColorFromGradientByPercent([{ r: 255, g: 0, b: 0 }, { r: 230, g: 230, b: 0 }, { r: 0, g: 255, b: 0 }], batPercentage);
    const batText = batPercentage < 0.05 ? '<5%' : `${Math.round(batPercentage * 100)}%`;

    return (
      <VStack /*borderRightWidth={[0, 0, 0, 1]}*/  borderLeftWidth={[0, 0, 0, 1]} borderTopWidth={[1, 1, 1, 0]} w={220} h={300} px={2}>
        <Text paddingTop={2} alignSelf="center" fontSize="xl" _dark={{
          color: "warmGray.50"
        }} color="coolGray.800" bold>
          Latest data
        </Text>
        <Text paddingY={1} alignSelf="center" fontSize="xl" _dark={{
          color: "warmGray.50"
        }} color="coolGray.800" bold>
          {new Date(scaleData.timestamp).toISOString().slice(0, 19).replace('T', ' ')}
        </Text>
        <Flex direction="row" paddingTop={2}>
          <Text w={100} fontSize={18}>Weight</Text>
          <Divider bg="emerald.500" thickness="2" mx="2" orientation="vertical" />
          <Text fontSize={18}>{scaleData.weight.toFixed(3)} kg</Text>
        </Flex>
        <Flex direction="row" paddingTop={2} w={200}>
          <Text w={100} fontSize={18}>Temp</Text>
          <Divider bg="emerald.500" thickness="2" mx="2" orientation="vertical" />
          <Text fontSize={18}>{scaleData.beetemp}°C</Text>
        </Flex>
        <Flex direction="row" paddingTop={2}>
          <Text w={100} fontSize={18}>Humidity</Text>
          <Divider bg="emerald.500" thickness="2" mx="2" orientation="vertical" />
          <Text fontSize={18}>{scaleData.humidity}%</Text>
        </Flex>
        <Flex direction="row" paddingTop={2}>
          <Text w={100} fontSize={18}>Pressure</Text>
          <Divider bg="emerald.500" thickness="2" mx="2" orientation="vertical" />
          <Text fontSize={18}>{scaleData.pressure} hPa</Text>
        </Flex>
        <View style={{ width: 80, alignSelf: 'center' }}>
          <svg viewBox='0 0 32 25'>

            <path fill={batFillColor} d="M 28 9 v 14 H 2 L 2 9 z M 31 12 h -1 V 9 c 0 -1 -1 -2 -2 -2 H 2 C 1 7 0 8 0 9 v 14.167 c 0 0.828 1 1.833 2 1.833 h 26 c 0.828 0 2 -1 2 -2 v -3 h 1 c 1 0 1 -1 1 -1 v -6 C 32 13 32 12 31 12 z z">
              {batPercentage <= 0.1 &&
                <animate
                  attributeType="XML"
                  attributeName="fill"
                  values="#FFF;#f00;#FFF"
                  dur="1.5s"
                  repeatCount="indefinite" />
              }
            </path>
            <path fill="rgb(0,0,0)" stroke="rgb(0,0,0)" d={`M ${batPercentValue} 10.5 v 11 H 3.5 L 3.5 10.5 z`}>

            </path>
            <text style={{
              fontSize: 10,
              fill: batFillColor
            }} x={26} y={19.5} textAnchor="end" viewBox='0 0 32 25' >{batText}</text>
          </svg>
        </View>
      </VStack>
    )
  }

  setScaleGraphWidth(width: number) {

    let height = 300;
    if(height / width > 1) {
      height = width;
    }
    this.setState({ scaleGraphWidth: width, scaleGraphHeight: height });
  }

  renderGraphListItem(info) {
    return (
      <Box borderBottomWidth="1" _dark={{
        borderColor: "muted.50"
      }} borderColor="muted.800" pl={[0, 3]} pr={[0, 3]} py={[0,0]}>
        <Stack 
            h={[undefined, undefined, undefined, this.state.scaleGraphHeight+40]} 
            direction={["column", "column", "column", "row"]} 
            space={[2, 3, 5, 5]} 
            justifyContent="space-between">
          <Center flex={1} mx={12} onLayout={(event) => {
            var { x, y, width, height } = event.nativeEvent.layout;
            //console.log(width);
            this.setScaleGraphWidth(width);
            
          }}>
            <Text fontSize="xl" bold>{info.item.name}</Text>
            {this.renderScaleGraph(info)}
          </Center>
          <Center>
            {this.renderScaleData(info.index)}
          </Center>

        </Stack>
      </Box>
    )
  }

  render() {

    //const graphWidth = this.state.windowDims.width * 0.8;

    return (
      <View style={[styles.container, { height: this.state.windowDims.height, width: '100%' }]}>
        <BeeHeader title="Home" navigation={this.props.navigation} />
        <Box flex={1} bg={{
          linearGradient: {
            colors: ['#EEE', '#E5E5E5'],
            start: [0, 1],
            end: [0, 0]
          }
        }}>
          { this.isLoggedIn() &&
            <FlatList backgroundColor="#FFF" mx={[0, 0, 0, 20, 40, 80]} data={this.scalesData} renderItem={this.renderGraphListItem.bind(this)} keyExtractor={item => item.id} />
          }

          { !this.isLoggedIn() &&
            <Box py={32}>
              <Center>
                <Text fontSize="xl">Please <Text fontSize="xl" onPress={() => this.props.navigation.navigate("Login")} bold>Sign in</Text> or <Text fontSize="xl" onPress={() => this.props.navigation.navigate("Register")} bold>Create a new account</Text>!</Text>
              </Center>
            </Box>
          }
        </Box>
      </View>
    );
  }
}


/*
<Center flex={1}>
            <VStack>
              <Text fontSize="2xl" _dark={{
                color: "warmGray.50"
              }} color="coolGray.800" bold>
                {info.item.name}
              </Text>
              <Text fontSize="xs" _dark={{
                color: "warmGray.50"
              }} color="coolGray.800" alignSelf="flex-start">
                {info.item.id}
              </Text>
            </VStack>
          </Center>
*/