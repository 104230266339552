const MaterialIcons = React.lazy(() => import("@expo/vector-icons/build/MaterialIcons"));
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Stack, Input, Icon, Pressable, Button } from 'native-base';
import React from 'react';
import { StyleSheet, View, Dimensions, NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { RootStackParamList } from '../App';
import { BeeHeader } from '../components/BeeHeader';
import * as AuthService from '../services/AuthService';
import { isValidEmail } from '../services/ValidateHelper';
import { RecoveryFlowBody, RecoveryResponse } from '../types/types';
type Props = NativeStackScreenProps<RootStackParamList, 'Login'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  email: string;
  showResetPassword: boolean;
  password: string;
  code: string;
  dataValid?: boolean;
  csrfToken?: string;
  flowId?: string;
  showPassword: boolean;
}

export default class RecoverScreen extends React.Component<Props, State> {

  emailTimeout;

  constructor(props) {
    super(props);
    this.state = { email: "", showResetPassword: false, code: "", showPassword: false, password: "" }
  }

  onEmailChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ email: event.nativeEvent.text }, () => {
      if (this.emailTimeout) {
        clearTimeout(this.emailTimeout);
      }
      this.emailTimeout = setTimeout(() => { this.emailTimeout = null; this.checkDataValid() }, 500);
    }
    );
  }

  checkDataValid() {
    this.setState({ dataValid: isValidEmail(this.state.email) });
  }

  onPasswordChanged(event: NativeSyntheticEvent<TextInputChangeEventData>) {
    this.setState({ password: event.nativeEvent.text });
    this.checkDataValid();
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onRecoverPressed() {
    AuthService.recoverUser(this.state.email).then(
      (data) => {
        //console.log("Data: ", data);
        this.setState({ showResetPassword: true, flowId: data.flowId, csrfToken: data.csrfToken }); // this.props.navigation.navigate('Home');
      }
    );
  }

  onSetPasswordPressed() {
    this.state.password
    AuthService.postFlowData<RecoveryResponse, RecoveryFlowBody>("recovery", this.state.flowId, { method: "code", email: undefined, code: this.state.code, csrf_token: this.state.csrfToken }).then(() => {
      AuthService.changePassword(this.state.password).then((value) => {
        this.props.navigation.navigate("Home");
      },
      (err) => {
        // TODO: handle error
      })
      
    },
      (err) => {
        // TODO: handle error
      });
  }

  render() {

    return (
      <View style={styles.container}>
        <BeeHeader title="Recover account" navigation={this.props.navigation} />
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight }}>
          {!this.state.showResetPassword &&
            <View style={{ alignItems: 'center', marginTop: 32 }}>
              <Stack w={400} alignItems="center">
                <Input mt={2} onChange={this.onEmailChanged.bind(this)} value={this.state.email} w={{
                  base: "75%",
                  lg: "100%"
                }} InputLeftElement={<Icon as={<MaterialIcons name="email" />} size={5} ml="2" color="muted.400" />} placeholder="Email" />

                <Button mt={2} w={140} disabled={!this.state.dataValid} onPress={this.onRecoverPressed.bind(this)}>Recover</Button>
              </Stack>
            </View>
          }
          {this.state.showResetPassword &&
            <View style={{ alignItems: 'center', marginTop: 32 }}>
              <Stack w={400} alignItems="center">
                <Input mt={2} onChangeText={(text) => this.setState({ code: text })} value={this.state.code} w={{
                  base: "75%",
                  lg: "100%"
                }} InputLeftElement={<Icon as={<MaterialIcons name="email" />} size={5} ml="2" color="muted.400" />} placeholder="Code" />
              
              <Input mt={4} onChange={this.onPasswordChanged.bind(this)} value={this.state.password} w={{
                base: "75%",
                lg: "100%"
              }} type={this.state.showPassword ? "text" : "password"} InputRightElement={<Pressable onPress={() => this.toggleShowPassword()}>
                <Icon as={<MaterialIcons name={this.state.showPassword ? "visibility" : "visibility-off"} />} size={5} mr="2" color="muted.400" />
              </Pressable>} placeholder="Password" />
              <Button mt={2} w={140} disabled={!this.state.dataValid} onPress={this.onSetPasswordPressed.bind(this)}>Set new Password</Button>

            </Stack>
            </View>
          }
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#FFF',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(255,255,255,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  },
  text: {
    height: 40,
    paddingHorizontal: 16,
    width: 180
  },
  textInput: {
    height: 40,
    borderWidth: 1,
    borderColor: "#222",
    borderRadius: 4,
    fontSize: 24,
    paddingHorizontal: 12,
    width: 400
  }
});
