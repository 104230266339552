import { CommonActions } from "@react-navigation/native";
import { Component } from "react";
import { TouchableOpacity, View, NativeEventEmitter } from "react-native";
import { styles } from "../constants/styles";
//import { globals } from "../global";
import * as AuthService from '../services/AuthService';
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { Box, Button, HStack, Icon, Pressable, Text } from "native-base";
import { colors } from "../constants/colors";
import { MaterialCommunityIcons } from '@expo/vector-icons';

type State = {
  showMenu: boolean;
}

type Props = {
  navigation: any;
  title: string;
}

export class BeeHeader extends Component<Props, State> {

  unsubscribeFocus;

  constructor(props: Props) {
    super(props);
    this.state = { showMenu: false }
  }

  componentDidMount(): void {
    //this.onAuthChanged();
    this.unsubscribeFocus = this.props.navigation.addListener('focus', () => {
      AuthService.addAuthListener(this.onAuthChanged.bind(this));
    });
    AuthService.whoami().then(() => {
      // do nothing here
    },
      (err) => {
        // do nothing
      });
  }

  componentWillUnmount(): void {
    if (this.unsubscribeFocus) {
      this.unsubscribeFocus();
      this.unsubscribeFocus = null;
    }
  }

  onAuthChanged() {
    if (!this.isLoggedIn()) {
      this.props.navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            { name: 'Home' }
          ],
        })
      );
      //this.props.navigation.navigate("Home");
    } else {
      this.setState({ showMenu: false });
      this.forceUpdate();
    }

  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  toggleUserMenu() {
    //this.setState({ showMenu: !this.state.showMenu });
  }

  /*
  getMenuText() {
    const authState = AuthService.getLoginState();
    if (authState == 'verified') {
      return globals.sessionData.identity.traits.email;
    }

    if (authState == 'unverified') {
      return globals.sessionData.identity.traits.email + '(unv.)';
    }

    return "Login/Register";
  }
  */

  isLoggedIn() {
    const authState = AuthService.getLoginState();

    if (authState == 'unverified' || authState == 'verified') {
      return true;
    }

    return false;
  }

  onLogin() {
    this.props.navigation.navigate('Login');
  }

  onVerification() {
    this.props.navigation.navigate('Verification');
  }

  onLogout() {
    AuthService.logout().then(() => {
      this.setState({ showMenu: false });
      this.forceUpdate();
    })
  }

  onRegister() {
    this.props.navigation.navigate('Register');
  }

  onHiveClicked() {
    this.props.navigation.navigate('Home');
  }

  render() {
    return (
      <>
        <Box h={12} w="100%" 
        bg={{
          linearGradient: {
            colors: [colors.primary2, colors.primary],
            start: [0, 0],
            end: [1, 0]
          }
        }}
        >
          <HStack justifyContent="space-between" alignItems="center" flex={1}>
            <HStack flex={1}>
              <Button variant="ghost" onPress={this.onHiveClicked.bind(this)}>
                <MaterialCommunityIcons name="beehive-outline" size={24} color={colors.textPrimary} />
              </Button>
            </HStack>
            <HStack flex={1} alignItems="center" justifyContent="center">
              <Text color={colors.textPrimary} fontSize="2xl">{this.props.title}</Text>
            </HStack>

            <HStack flex={1} justifyContent="flex-end">

              <HStack alignItems="center">

                {this.isLoggedIn() &&
                  <Button variant="ghost" onPress={this.toggleUserMenu.bind(this)}>
                    <Icon as={<MaterialIcons name="account-circle" color={colors.textPrimary} />} size={8} />
                  </Button>
                }
                <Button variant="ghost" onPress={this.toggleMenu.bind(this)}>
                  <Icon as={<MaterialIcons name="menu" />} size={8} color={colors.textPrimary}/>
                </Button>
              </HStack>

            </HStack>


          </HStack>
        </Box>
        {this.state.showMenu && (
          <View style={styles.menuContainer}>
            <View style={styles.menu}>

              <View style={styles.menuBody}>
                {this.isLoggedIn() ? (
                  <TouchableOpacity style={styles.menuItem}>
                    <Text style={styles.menuItemText} onPress={this.onLogout.bind(this)}>Logout</Text>
                  </TouchableOpacity>
                ) : (
                  <>
                    <TouchableOpacity style={styles.menuItem} onPress={this.onLogin.bind(this)}>
                      <Text style={styles.menuItemText}>Login</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.menuItem} onPress={this.onRegister.bind(this)}>
                      <Text style={styles.menuItemText}>Register</Text>
                    </TouchableOpacity>
                    <View style={styles.separator} />
                    <TouchableOpacity style={styles.menuItem} onPress={this.toggleMenu.bind(this)}>

                      <Text style={styles.menuItemText}>Close</Text>

                    </TouchableOpacity>
                  </>
                )}
              </View>
            </View>
          </View>
        )}
      </>
    );
  }
}