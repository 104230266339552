import { RGBColor } from "../types/types";

var gradient = [
    
    [
        0,
        [0,128,0]
    ],
    [
        50,
        [255,128,0]
    ],
    [
        100,
        [255,0,0]
    ]
];


export function getColorFromGradientByPercent(gradient: Array<RGBColor>, percentage) : string {
    const colorIndex = (percentage * (gradient.length-1));
   
    const colorIndex1 = Math.floor(colorIndex);
    const colorIndex2 = Math.min(gradient.length-1, Math.ceil(colorIndex));
    const colorRatio = colorIndex - colorIndex1;
    const rgb = pickHex(gradient[colorIndex1], gradient[colorIndex2], colorRatio);

    return `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
}

function pickHex(color1: RGBColor, color2: RGBColor, weight) {
    var p = weight;
    var w = p;// * 2 - 1;
    var w2 = w; //(w/1+1) / 2;
    var w1 = 1 - w2;
    var rgb = [Math.round(color1.r * w1 + color2.r * w2),
        Math.round(color1.g * w1 + color2.g * w2),
        Math.round(color1.b * w1 + color2.b * w2)];
    return rgb;
}